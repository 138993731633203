
$(function(){

  var botui = false;
  $('#chat-icon').on("click",function(e){
    $('#welcome-chat').removeClass('hidden');
    $(this).addClass('hidden');
    if(!botui){
      botui = new BotUI('chat-wrapper');
      send_message('StartConversation', false);
    }
  });


  $("#chat-close").on("click",function(e){
      $('#welcome-chat').addClass('hidden');
      $('#chat-icon').removeClass('hidden');
  });

  $('.chat-message').on("focus",function(e){
    $('#chat-send').removeClass('hidden');
  });



  $('#welcome-chat .chat-message-text').on('keyup', function (e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
        handle_send();
    }
  });


  $('#chat-send').click(function(){
    handle_send();
  });


  function handle_send(){
    var message = $('.chat-message:visible').val().trim();

    if( message == ''){
        send_message(message, false, false);
        $('.chat-message').val('');
    } else {
      send_message(message, true, false);
      $('.chat-message').val('');
    }
  }


  function send_message(message, show = true, interactive = false, min_response_time = 500){

    if(show){
      botui.message.add(
        {
          loading: false,
          type: 'text',
          content: message,
          human: true
        }
      );

    };

    var token = $('#chat-token').val();
    var site = $('#chat-site-id').val();


    if(token.trim() == '' || site.trim() == ''){
      show_problem_message(true);
      return;
    }
    var startTime = performance.now();

    $.post('/api/v1/chat/listen' , {

      'driver': 'web',
      'userId': token,
      'site': site,
      'message': message,
      'attachment': null,
      'interactive': interactive,
    },

    function(o){

      var endTime = performance.now();

      var timeDiff = endTime - startTime; //in ms
      var pause = 0;

      if(timeDiff < min_response_time){
          pause = min_response_time - timeDiff;
      }


    if(typeof(o.messages.length) == 'undefined'){
      show_problem_message(true);
    }  else {

      if(o.messages.length == 0){
        $('.chat-message-text').removeClass('hidden');

  console.log('success, but no message.');
  console.log(o);

  //      show_problem_message();
      } else {
        setTimeout(function(){
          show_responses(0, o.messages);
        }, pause);
      }


    }



    })

    .fail(function(o) {

console.log('fail:');
console.log(o);

      show_problem_message(true);

    });


    function show_responses(i, responses){

      var response = responses[i];

      console.log(response);

      setTimeout(function(){


        response.pattern = '';
        if(typeof(response.additionalParameters.ask) != 'undefined'){
          if(response.additionalParameters.ask == true){
            response.type = 'ask';

            if(typeof(response.additionalParameters.pattern) != 'undefined'){
              response.pattern = response.additionalParameters.pattern;
            }

          }
        } else {
        }



        switch (response.type){

          case 'text':

            botui.message.add(
              {
                delay: rnd_delay(response.text) * 1000,
                loading: true,
                type: 'text',
                content: response.text,
                human: false
              }
            )
            .then(function(){

              if(i < responses.length - 1){
                i++;
                show_responses(i, responses);
              }

            });

            break;


          case 'ask':
            botui.message.add(
              {
                delay: rnd_delay(response.text) * 1000,
                loading: true,
                type: 'text',
                content: response.text,
                human: false
              }
            )
            .then(function(){

              if(i < responses.length - 1){
                i++;
                show_responses(i, responses);
              }


            });

            break;

          case 'actions':


            botui.message.add(
              {
                delay: rnd_delay(response.text) * 1000,
                loading: true,
                type: 'text',
                content: response.text,
                human: false
              }
            ).then(
              function(){

                var buttons = [];

                response.actions.forEach(function(action){
                  console.log(action);

                  switch (action.type) {
                    case 'button':

                      buttons.push({
                        text: action.text,
                        value: action.value
                      });

                      break;

                  }

                });


                if(buttons.length > 0){

                  botui.action.button({
                        delay: 1000,
                        action: buttons
                  }).then(function (res){
                    send_message(res.value, false, true);
                  });


                }


            });



            break;


          }

      }, rnd_delay() * 1000);


    }

  }



  function show_problem_message(error){


    var message;
    if(error){
      message = "I'm sorry, but I'm having trouble connecting to our system. Please wait a minute and try again.";
    } else {
      message = "I'm sorry, but I don't understand. Could you try again?";
    }

    botui.message.add(
      {
        delay: rnd_delay(message) * 1000,
        loading: true,
        type: 'text',
        content: message,
      human: false
      }
    )

  }

  function rnd_delay(text = '', min = .1, max = .5){

    var rnd = Math.random() * (max - min) + min;
    rnd+ (text.length * .075);
    if(rnd > (max * 3)){
      rnd = max * 3;
    }

    return rnd;
  }



});
