
window.jQuery = window.$ = require("jquery");
window.uuid = require('uuid');
window.BotUI = require('botui');


require("popper.js");

require('./bootstrap');





window.Vue = require('vue');
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


/*
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
const app = new Vue({
    el: '#app',
});
*/
